.ck.ck-balloon-panel {
  z-index: 1400 !important;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

/* Add this CSS to your global stylesheet or component-specific stylesheet */
.ck-content a {
  color: blue;
  text-decoration: underline;
}

.ck-content a:hover {
  color: darkblue;
}

.ck-content h1 {
  font-size: 22px;
  font-weight: bold;
  margin: 16px 0;
  line-height: 1.3;
  color: #333333; /* Optional: Adding color for better readability */
}

.ck-content h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 14px 0;
  line-height: 1.3;
  color: #333333; /* Optional: Adding color for better readability */
}

.ck-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 12px 0;
  line-height: 1.3;
}

.ck-content h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
  line-height: 1.3;
}

.ck-content h5 {
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
  line-height: 1.3;
}

.ck-content h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 6px 0;
  line-height: 1.3;
}

.ck-content p {
  font-size: 14px; /* Adjusted size for typical email readability */
  font-weight: normal;
  margin: 10px 0; /* Adjusted margin for better spacing */
  line-height: 1.6; /* Adjusted line-height for readability */
  color: #333333; /* Optional: Adding color for better readability */
}


.ck-editor__editable {
  border: 2px !important;
  height: 348px;
  border-color: #333333 !important;
}
