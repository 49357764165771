@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
}

body {
  @apply h-full;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray {
  @apply bg-[#637381];
}

.bg-gray-hover {
  @apply hover:bg-[#475361];
}

.text-gray {
  @apply text-[#637381];
}

.text-gray-hover {
  @apply hover:text-[#475361];
}

.login-background-img {
  background-image: linear-gradient(180deg, #e65d9040, #2364aa),
    url("Assets/Images/login-page-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: contain; */
  /* transform: scaleX(-1) */
}

.btn-orange {
  @apply font-medium text-sm py-2 px-3 bg-orange-400 text-white shadow-sm shadow-gray-300 rounded;
}
.btn-orange:hover {
  @apply bg-orange-500;
}
.btn-orange-sm {
  @apply font-medium text-xs py-2 px-2 bg-orange-400 text-white shadow-sm shadow-gray-300 rounded;
}
.btn-orange-sm:hover {
  @apply bg-orange-500;
}
.icon-btn-orange {
  @apply font-medium text-xs p-1 bg-orange-400 text-white shadow-sm shadow-gray-300 rounded;
}
.icon-btn-orange:hover {
  @apply bg-orange-500;
}

.btn-orange:disabled {
  @apply opacity-50 cursor-not-allowed; /* Example styles for a disabled button */
}

.icon-btn-orange:disabled {
  @apply opacity-50 cursor-not-allowed; /* Example styles for a disabled icon button */
}

/* table css */
.dataGridCustom {
  background-color: white;
  outline: none;
}

.dataGridCustom .css-1iyq7zh-MuiDataGrid-columnHeaders {
  @apply font-bold bg-[#dadcdf];
}

.dataGridCustom .css-t89xny-MuiDataGrid-columnHeaderTitle {
  @apply font-semibold;
}

.dataGridCustom .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  @apply text-gray;
}

.dataGridCustom .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  @apply text-black font-semibold;
}

.dataGridCustom .css-y6rp3m-MuiButton-startIcon > *:nth-of-type(1) {
  @apply text-orange-400;
}

.dataGridCustom .MuiDataGrid-cell {
  @apply !overflow-visible;
}

.actions-button-cell {
  overflow: visible !important;
  user-select: none;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.ag-root,
.ag-body-viewport,
.ag-body-viewport-wrapper {
  overflow: visible !important;
}

/* Reduce pagination height */
.dataGridCustom .MuiDataGrid-pagination {
  max-height: 1px !important; /* Adjust height as needed */
  min-height: 45px !important;
  padding: 0px !important;
  /* Other styles */
}

/* Alternatively, you can target the container holding pagination controls */
.dataGridCustom .MuiDataGrid-footerContainer {
  max-height: 1px !important; /* Adjust height as needed */
  min-height: 45px !important;
  padding: 0px !important;
  /* Other styles */
}

/* scrollbar css */

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-zinc-100;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-zinc-400 rounded-xl;
  cursor: default !important;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-orange-400;
}

.MuiDataGrid-cell :focus {
  box-shadow: none;
}

.ag-header-viewport {
  @apply bg-gray-300 text-white text-sm;
}

.ag-header-row .ag-header-row-column {
  @apply bg-gray-500;
}

.ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
  @apply bg-gray-400 h-full !top-0 !left-1 !z-0;
}

.ag-pinned-left-header .ag-header-cell-resize::after {
  @apply bg-gray-400 h-full !top-0 !left-1 !z-0;
}

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
  min-height: 100px;
}

.ag-overlay-no-rows-center {
  margin-top: 100px;
}

.ag-modal-body .ag-body {
  overflow-y: scroll !important;
  max-height: 300px !important;
}

.ag-body {
  overflow-y: scroll !important;
}

/* lead table height */

.lead-table-height .ag-body {
  overflow-y: scroll !important;
}

@media screen and (max-height: 4000px) {
  .lead-table-height .ag-body {
    max-height: 80vh;
  }
}
@media screen and (max-height: 3000px) {
  .lead-table-height .ag-body {
    max-height: 76vh;
  }
}
@media screen and (max-height: 2160px) {
  .lead-table-height .ag-body {
    max-height: 71vh;
  }
}
@media screen and (max-height: 2000px) {
  .lead-table-height .ag-body {
    max-height: 68vh;
  }
}
@media screen and (max-height: 1430px) {
  .lead-table-height .ag-body {
    max-height: 62vh;
  }
}
@media screen and (max-height: 1300px) {
  .lead-table-height .ag-body {
    max-height: 57vh;
  }
}
@media screen and (max-height: 1190px) {
  .lead-table-height .ag-body {
    max-height: 53vh;
  }
}
@media screen and (max-height: 1080px) {
  .lead-table-height .ag-body {
    max-height: 52vh;
  }
}
@media screen and (max-height: 1020px) {
  .lead-table-height .ag-body {
    max-height: 55vh;
  }
}
@media screen and (max-height: 960px) {
  .lead-table-height .ag-body {
    max-height: 60vh;
  }
}
@media screen and (max-height: 900px) {
  .lead-table-height .ag-body {
    max-height: 60vh;
  }
}
@media screen and (max-height: 850px) {
  .lead-table-height .ag-body {
    max-height: 58vh;
  }
}
@media screen and (max-height: 800px) {
  .lead-table-height .ag-body {
    max-height: 48vh;
  }
}

@media screen and (max-height: 700px) {
  .lead-table-height .ag-body {
    max-height: 45vh;
  }
}

@media screen and (max-height: 600px) {
  .lead-table-height .ag-body {
    max-height: 28vh;
  }
}

/* custom table height css */

.custom-table-height .ag-body {
  overflow-y: scroll !important;
}

@media screen and (max-height: 4000px) {
  .custom-table-height .ag-body {
    max-height: 100vh;
  }
}
@media screen and (max-height: 3000px) {
  .custom-table-height .ag-body {
    max-height: 90vh;
  }
}
@media screen and (max-height: 2160px) {
  .custom-table-height .ag-body {
    max-height: 85vh;
  }
}
@media screen and (max-height: 2000px) {
  .custom-table-height .ag-body {
    max-height: 83vh;
  }
}
@media screen and (max-height: 1500px) {
  .custom-table-height .ag-body {
    max-height: 75vh;
  }
}
@media screen and (max-height: 1190px) {
  .custom-table-height .ag-body {
    max-height: 75vh;
  }
}
@media screen and (max-height: 1080px) {
  .custom-table-height .ag-body {
    max-height: 70vh;
  }
}
@media screen and (max-height: 1020px) {
  .custom-table-height .ag-body {
    max-height: 66vh;
  }
}
@media screen and (max-height: 960px) {
  .custom-table-height .ag-body {
    max-height: 62vh;
  }
}
@media screen and (max-height: 800px) {
  .custom-table-height .ag-body {
    max-height: 55vh;
  }
}
@media screen and (max-height: 700px) {
  .custom-table-height .ag-body {
    max-height: 45vh;
  }
}
/* ___ */

.ag-body .ag-cell {
  display: inline-block;
  margin-top: 0px;
}

.ag-body-vertical-scroll-viewport {
  overflow-y: hidden !important;
}
.ql-toolbar {
  position: sticky;
  z-index: 10000;
}
.ql-container {
  max-height: 300px;
  overflow-y: auto;
}

.react-select__menu {
  z-index: 9999;
}
.ag-pinned-left-header {
  @apply bg-gray-300 text-gray-500;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

input[type="file" i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-overflow: ellipsis;
  text-align: start !important;
  padding: initial;
  border: initial;
  white-space: pre;
  overflow: hidden !important;
}

.leads-table-loading {
  z-index: 9;
  height: 60vh;
}

.mentions__input {
  height: 80px !important;
  overflow-y: scroll !important;
  color: transparent;
  caret-color: black !important;
}
.mentions__highlighter__substring {
  color: black !important;
  visibility: visible !important;
  cursor: text;
}

.mentions__highlighter {
  height: 80px !important;
  overflow-y: scroll !important;
}
.ag-theme-alpine {
  --ag-cell-horizontal-border: var(--ag-row-border-width)
    var(--ag-row-border-style) var(--ag-row-border-color) !important; /* Add left and right borders to each cell and use row border's properties  */
  --ag-header-column-separator-display: block;
}

.ql-editor {
  min-height: 190px;
}
.ag-header-cell {
  @apply border-r-2 !border-gray-400 h-10;
}
.ag-pinned-left-header {
  border-right: none !important;
}

.ag-header-group-cell {
  justify-content: center !important;
  /* width: 426px !important; */
}

.ag-row .ag-cell {
  display: block;
  align-items: center;
  overflow: hidden;
}

.ag-header-group-cell-with-group {
  @apply border-r-2 !border-gray-400 h-10;
}

.ag-header-allow-overflow {
  @apply !pr-[6px];
}

.ag-header {
  @apply !bg-gray-300;
}

.ag-cell-inline-editing {
  @apply !h-[100%];
}

.ag-cell-inline-editing {
  height: 100% !important;
}

.ag-header-cell-resize:after {
  @apply !z-0;
}
.ag-header-cell-resize {
  @apply !z-0;
}

.fadeout {
  animation: fade 3s ease-in-out;
}

/* CSS for MUI x-date-pickers */
.css-4jnixx-MuiStack-root {
  padding-top: 3px !important;
}

@keyframes fade {
  from {
    margin-right: 0px;
    opacity: 1;
  }
  to {
    margin-left: 100px;
    opacity: 0;
    background-color: rgb(243, 229, 204);
  }
}

.rdrInputRangeInput {
  width: 60px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: orange !important;
}
.react-datepicker__calendar-icon {
  color: #2364aa !important;
}

.react-datepicker__close-icon::after {
  background-color: orange !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  position: fixed !important;
}

.btn {
  @apply py-1 px-3 bg-orange text-white shadow-sm shadow-gray-300;
}

.bg-orange {
  @apply bg-orange-500 hover:bg-orange-600;
}
.text-orange {
  @apply text-orange-500;
}
.text-gray {
  @apply text-gray-600;
}
.leadDeatils-card-header {
  @apply bg-orange-400;
}
.leadDeatils-card-body {
  @apply bg-white;
}
.btn-sms {
  @apply py-1 px-3 bg-green-600 text-white shadow-sm shadow-gray-300;
}
.btn-story {
  @apply bg-blue-600 text-white shadow-sm shadow-gray-300;
}
.btn-notes {
  @apply py-1 px-3 bg-emerald-600 text-white shadow-sm shadow-gray-300;
}
.btn-call-log {
  @apply py-1 px-3 bg-cyan-600 text-white shadow-sm shadow-gray-300;
}
.leadDetails-Bluecard-header {
  @apply bg-blue-400;
}
.leadDeatils-Bluecard-body {
  @apply bg-blue-50;
}

/* To hide only the scrollbar without hiding the content overflow */
.scrollbar-hide {
  /* scrollbar-width: none; Firefox */
  /* -ms-overflow-style: none; Internet Explorer 10+ */
}

.scrollbar-hide::-webkit-scrollbar {
  /* WebKit browsers (Chrome, Safari) */
  /* display: none; */
}
.clr-i-outline-path-1,
.clr-i-outline-path-2,
.clr-i-outline-path-3 {
  fill: white;
}

.multi-select-sticky {
  position: sticky !important;
}

/* Blue scroll-bar */
.scrollbar-blue::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-blue::-webkit-scrollbar-thumb:hover {
  background-color: #60a5fa;
  border-radius: 10px;
}

/* Green scroll-bar */
.scrollbar-green::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-green::-webkit-scrollbar-thumb:hover {
  background-color: #4ade80;
  border-radius: 10px;
}

/* Teal scroll-bar */
.scrollbar-teal::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-teal::-webkit-scrollbar-thumb:hover {
  background-color: #2dd4bf;
  border-radius: 10px;
}

/* Purple scroll-bar */
.scrollbar-purple::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-purple::-webkit-scrollbar-thumb:hover {
  background-color: #a78bfa;
  border-radius: 10px;
}

/* Indigo scroll-bar */
.scrollbar-indigo::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-indigo::-webkit-scrollbar-thumb:hover {
  background-color: #6366f1;
  border-radius: 10px;
}

/* Emarald scroll-bar */
.scrollbar-emerald::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-emerald::-webkit-scrollbar-thumb:hover {
  background-color: #34d399;
  border-radius: 10px;
}

/* Red scroll-bar */
.scrollbar-red::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-red::-webkit-scrollbar-thumb:hover {
  background-color: #f87171;
  border-radius: 10px;
}

/* Cyan scroll-bar */
.scrollbar-cyan::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-cyan::-webkit-scrollbar-thumb:hover {
  background-color: #22d3ee; /* Tailwind cyan-400 color */
  border-radius: 10px;
}

/* Amber scroll-bar */
.scrollbar-emerald::-webkit-scrollbar {
  width: 6px;
}
.scrollbar-amber::-webkit-scrollbar-thumb:hover {
  background-color: #fbbf24;
  border-radius: 10px;
}

[id^="react-select-"][id$="-input"] {
  outline: none !important;
  box-shadow: none !important;
}

.ql-toolbar {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.ql-container {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  overflow: auto !important;
}

.mergeTable .mergeTBody {
  display: block;
  overflow-y: scroll;
}

.mergeTable .mergeThead,
.mergeTbody .mergeTableRow {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.css-100ebu3-menu {
  z-index: 99999 !important;
}

.recharts-sector {
  outline: none !important;
  border: none !important;
}

.recharts-layer {
  outline: none !important;
  border: none !important;
}
/* task-container */
.task-container-height {
  height: auto;
}

@media screen and (max-height: 4000px) {
  .task-container-height {
    max-height: 90vh;
  }
}
@media screen and (max-height: 3000px) {
  .task-container-height {
    max-height: 86vh;
  }
}
@media screen and (max-height: 2160px) {
  .task-container-height {
    max-height: 70vh;
  }
}
@media screen and (max-height: 2000px) {
  .task-container-height {
    max-height: 70vh;
  }
}
@media screen and (max-height: 1430px) {
  .task-container-height {
    max-height: 78vh;
  }
}
@media screen and (max-height: 1300px) {
  .task-container-height {
    max-height: 66vh;
  }
}
@media screen and (max-height: 1020px) {
  .task-container-height {
    max-height: 63vh;
  }
}
@media screen and (max-height: 960px) {
  .task-container-height {
    max-height: 58vh;
  }
}
@media screen and (max-height: 900px) {
  .task-container-height {
    max-height: 58vh;
  }
}
@media screen and (max-height: 850px) {
  .task-container-height {
    max-height: 46vh;
  }
}
@media screen and (max-height: 800px) {
  .task-container-height {
    max-height: 46vh;
  }
}
@media screen and (max-height: 700px) {
  .task-container-height {
    max-height: 40vh;
  }
}

@media screen and (max-height: 600px) {
  .task-container-height {
    max-height: 40vh;
  }
}

/* type container  */

@media screen and (max-height: 4000px) {
  .type-height {
    max-height: 66vh;
  }
}
@media screen and (max-height: 3000px) {
  .type-height {
    max-height: 65vh;
  }
}
@media screen and (max-height: 2160px) {
  .type-height {
    max-height: 65vh;
  }
}
@media screen and (max-height: 2000px) {
  .type-height {
    max-height: 59vh;
  }
}
@media screen and (max-height: 1430px) {
  .type-height {
    max-height: 59vh;
  }
}
@media screen and (max-height: 1300px) {
  .type-height {
    max-height: 52vh;
  }
}
@media screen and (max-height: 1020px) {
  .type-height {
    max-height: 47vh;
  }
}
@media screen and (max-height: 960px) {
  .type-height {
    max-height: 43vh;
  }
}
@media screen and (max-height: 900px) {
  .type-height {
    max-height: 40vh;
  }
}
@media screen and (max-height: 850px) {
  .type-height {
    max-height: 35vh;
  }
}
@media screen and (max-height: 800px) {
  .type-height {
    max-height: 33vh;
  }
}
@media screen and (max-height: 700px) {
  .type-height {
    max-height: 25vh;
  }
}
@media screen and (max-height: 600px) {
  .type-height {
    max-height: 25vh;
  }
}
.custom-header-cell-small {
  padding-left: 12px !important ;
}

.MuiInputBase-input {
  padding: 10px 0px 10px 10px !important;
  font-size: 15px !important;
}

.MuiIconButton-root {
  padding: 4px !important;
  font-size: 15px !important;
}

.MyDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid rgb(218, 214, 214);
}
.MyDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid black;
}

.MyDatePicker input {
  --tw-ring-shadow: 0 0 #000 !important;
}

.MuiMultiSectionDigitalClockSection-root::after {
  height: 0px !important;
}

@media (max-width: 768px) {
  /* Adjust the max-width as needed for mobile screens */

  .task-list-scroll {
    /* Add this to make the element scrollable */
    overflow-y: scroll;
  }

  /* Custom scrollbar styling */
  .task-list-scroll::-webkit-scrollbar {
    width: 18px !important; /* Adjust the width as needed */
  }

  .task-list-scroll::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb color */
    border-radius: 10px;
  }

  .task-list-scroll::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Track color */
  }
}

@media (max-width: 500px) {
  .css-mohuvp-dummyInput-DummyInput {
    width: min-content !important;
  }
}



@media (max-width: 768px) {
  /* Adjust the max-width as needed for mobile screens */

  .quick-message-scroll {
    /* Add this to make the element scrollable */
    overflow-y: scroll;
  }

  /* Custom scrollbar styling */
  .quick-message-scroll::-webkit-scrollbar {
    width: 10px !important; /* Adjust the width as needed */
  }

  .quick-message-scroll::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb color */
    border-radius: 10px;
  }

  .quick-message-scroll::-webkit-scrollbar-track {
    background-color: #ffffff; /* Track color */
  }
}